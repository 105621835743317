import React from "react";
import styled from "styled-components";
import {Container} from "styled-bootstrap-grid";
import theme from "../../styles/theme";
import {Link} from "gatsby";
import PlusLink from "../../resources/images/home_page/plus.svg";
import fonts from "../../styles/fonts";
import {Fade} from "react-reveal";
import { getProjectLink } from "../../services/links"
import btoa from "btoa"

const NextProjectBlock = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  @media(min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const Left = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 225px;
  background: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 35px;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    height: 475px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 164px;
  }
`;

const Right = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 225px;
  object-fit: cover;
  position: relative;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    height: 475px;
  }
`;

const NextProjectImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 225px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 475px;
  }
`;

const LinkStyled = styled(Link)`
  display: block;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  background: url(${PlusLink}) no-repeat;
  background-size: 100%;
  @media(min-width: ${theme.breakpoints.medium}) {
    right: 55px;
    bottom: 55px;
    height: 46px;
    width: 46px;
    z-index: 11;
    transition: .6s;
    &:hover {
      transform: rotate(360deg);
      transition: .6s;
    }
  }
`;

const ProjectName = styled.div`
  ${fonts.MaisonNeueLight};
  color: ${theme.colors.white};
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 19.2px;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 21.6px;
  }
`;

const ClientName = styled.div`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  color: ${theme.colors.white};
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 46px;
    line-height: 55.2px;
  }
`;

const ImageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.white};
`

const NextProject = ({next, clientName, clientImage, articleLists}) => {
  let projectLink = getProjectLink(next)

  if (articleLists) {
    projectLink = projectLink + `/?list=${btoa(JSON.stringify(articleLists))}`
  }
    return (
        <NextProjectBlock>
            <Left className="cursor-white">
                <ProjectName>next project</ProjectName>
                <StyledLink to={projectLink}>
                  <ClientName className="cursor-dot-white">{clientName}</ClientName>
                </StyledLink>
            </Left>
            <Right>
                <ImageBlock>
                    <Fade duration={500}>
                        <NextProjectImage src={clientImage} alt={clientName}/>
                    </Fade>
                </ImageBlock>
                <div className="cursor-dot-black">
                  <LinkStyled to={projectLink}/>
                </div>
            </Right>
        </NextProjectBlock>
    )
};

export default NextProject
