import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {Container} from 'styled-bootstrap-grid';
import {Fade} from "react-reveal";
import theme from "../../styles/theme";

const ContainerStyled = styled(Container)`
  padding: 6px 0;
`;

const FullWidthImg = styled(Img)`
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  
  @media(min-width: ${theme.breakpoints.medium}){
    height: auto;
    max-height: 100vh;
  }
`;

const VideoBlock = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  
  iframe {
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    height: 100vh;
  }
  @media(min-width: 420px){
    min-height: 250px;

  }
  @media(min-width: 560px){
    min-height: 300px;
  }
  @media(min-width: ${theme.breakpoints.medium}){
    max-height: 794px;
    height: 50vw;
    iframe {
      height: 870px;
    }
  }
  @media(min-width: 1600px){
    width: 1600px;
    iframe {
      height: 970px;
      width: 1670px;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const FullWidthImage = ({image, videoUrl, carousel}) => {
  return (
    <ContainerStyled>
      <Fade duration={750}>
        {videoUrl ?
          <VideoBlock carousel={carousel}>
            <Overlay />
            <iframe src={`${videoUrl}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
                    frameBorder="0" allow="fullscreen; autoplay" allowFullScreen={true} title="Project Video"/>
          </VideoBlock>
          :
          <FullWidthImg fluid={image} carousel={carousel}/>
        }
      </Fade>
    </ContainerStyled>

  )
};

export default FullWidthImage;
