import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import {Container} from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const TextImageBlockContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 6px 0;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-height: 100vh;
    flex-direction: row;
  }
`;
const Left = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    margin-right: 6px;
  }
`;

const Right = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    margin-left: 6px;
  }
`;

const TextBlock = styled.div`
  ${fonts.CircularMedium};
  font-size: 24px;
  line-height: 28px;
  padding: 45px 35px 45px 35px;
  box-sizing: border-box;
  @media(min-width: 850px){
    font-size: 32px;
    line-height: 40px;
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    letter-spacing: 0;
    padding: 0 17.5%;
  }
`;

const ProjectImage = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 250px;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-height: 100vh;
  }
`;

const VideoBlock = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 360px;
  min-height: 100vw;
  
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
  @media(min-width: 560px){
    min-height: 500px;
  }
  
  @media(min-width: ${theme.breakpoints.medium}){
    height: 50vw;
    min-height: unset;
    iframe {
        box-sizing: border-box;
        height: calc(50vw - 6px);
        left: 0;
        position: absolute;
        top: 0;
        width: 50vw;
    }
  }
  
  @media(min-width: 1600px){
    height: 736px;
    width: 794px;
    iframe {
      height: 780px;
      width: 100%;
    }
  }
`;

const ImageBlock = styled.div`
  height: 100%;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`

const TextImageBlock = ({image, type, content, videoUrl}) => {
  return (
    <TextImageBlockContainer>
        <Left>
            {type === 'imgFirst' ?
                <React.Fragment>
                    {videoUrl ?
                        <VideoBlock>
                          <Overlay />
                            <Fade duration={750}>
                            <iframe
                                src={`${videoUrl}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
                                title="Project Video"
                                frameBorder="0"
                                allow="fullscreen; autoplay"
                                allowFullScreen={true}/>
                            </Fade>
                        </VideoBlock>
                        :
                        <ImageBlock>
                            <Fade duration={750}>
                                <ProjectImage fluid={image}/>
                            </Fade>
                        </ImageBlock>
                    }
                </React.Fragment>
                :
                <Fade duration={750}>
                    <TextBlock>{content}</TextBlock>
                </Fade>
            }
      </Left>
      <Right>
        {type === 'imgFirst' ?
            <Fade duration={750}>
                <TextBlock>{content}</TextBlock>
            </Fade>
          :
            <React.Fragment>
                {
                    videoUrl ?
                        <VideoBlock>
                          <Overlay />
                            <Fade duration={750}>
                                <iframe
                                    src={`${videoUrl}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
                                    title="Project Video"
                                    frameBorder="0"
                                    allow="fullscreen; autoplay"
                                    allowFullScreen={true}/>
                            </Fade>
                        </VideoBlock>
                        :
                        <ImageBlock>
                            <Fade duration={750}>
                                <ProjectImage fluid={image}/>
                            </Fade>
                        </ImageBlock>
              }
          </React.Fragment>
        }
      </Right>
    </TextImageBlockContainer>
  )
};

export default TextImageBlock;
