import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import ProjectHero from "../components/project/ProjectHero";
import ProjectImageBlock from "../components/project/ProjectImageBlock";
import TextImageBlock from "../components/project/TextImageBlock";
import FullWidthImage from "../components/project/FullWidthImage";
import fonts from "../styles/fonts";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import get from "lodash/get";
import { Container } from "styled-bootstrap-grid";
import theme from "../styles/theme";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import NextProject from "../components/project/NextProject";
import LargeImagesBlock from "../components/project/LargeImagesBlock";
import Carousel from "../components/project/Carousel";
import { Fade } from "react-reveal";
import atob from "atob"

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const PageBackground = styled.div`
  background: ${theme.colors.white};
`;

const ContainerStyled = styled(Container)`
  padding: 6px 0;
`;

const TextContainer = styled.div`
  width: 100%;
  ${fonts.CircularMedium};
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
  color: ${theme.colors.black};
  padding: 65px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 730px;
  margin: auto;
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 32px;
    line-height: 40px;
    padding-top: 175px;
    padding-bottom: 175px;
  }
`;

const ProjectTemplate = (props) => {
  const path = props.location.pathname;
  const pageContext = props.pageContext;
  const project = props.data.contentfulProject;
  const projects = props.data.projects.nodes;
  let blocks = project.blocks;
  const { next, clientName, clientImage } = pageContext;
  const [articleLists, setArticleLists] = useState();
  const [nextArticleData, setNextArticleData] = useState({
    slug: next,
    clientName,
    clientImage,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = props.location.search.replace("?list=", "");
      if (searchParams) {
        let list = atob(searchParams);
        if (list) {
          setArticleLists(JSON.parse(list));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (articleLists) {
      const currentIndex = articleLists.findIndex(
        (slug) => slug === project.slug
      );
      const nextIndex =
        currentIndex + 1 < articleLists.length ? currentIndex + 1 : 0;
      const nextProject = projects.find(
        (projectData) => projectData.slug === articleLists[nextIndex]
      );

      if (nextProject) {
        nextProject.clientImage = nextProject.thumbnail.file.url
        setNextArticleData(nextProject)
      }
    }
  }, [articleLists]);

  if (!blocks) {
    blocks = [];
  }

  let pageBlocks = [];

  blocks.forEach((block) => {
    let blockType = block.__typename;

    if (blockType === "ContentfulBlockImage") {
      pageBlocks.push(
        <ProjectImageBlock projectImage={[block.image.fluid]} key={block.id} />
      );
    }

    if (blockType === "ContentfulBlockTextImage") {
      pageBlocks.push(
        <TextImageBlock
          key={block.id}
          videoUrl={block.videoUrl}
          image={block.image && [block.image.fluid]}
          content={documentToReactComponents(block.content.json)}
          type={
            block.imagePosition && block.imagePosition === true
              ? "imgFirst"
              : ""
          }
        />
      );
    }

    if (blockType === "ContentfulBlockTwoImages") {
      if (block.withBackground === true) {
        pageBlocks.push(
          <ProjectImageBlock
            key={block.id}
            projectImage={
              block.firstImage &&
              block.secondImage && [
                block.firstImage.fluid,
                block.secondImage.fluid,
              ]
            }
          />
        );
      } else {
        pageBlocks.push(
          <LargeImagesBlock
            key={block.id}
            projectImage={
              block.firstImage &&
              block.secondImage && [
                block.firstImage.fluid,
                block.secondImage.fluid,
              ]
            }
          />
        );
      }
    }

    if (blockType === "ContentfulBlockText") {
      pageBlocks.push(
        <ContainerStyled key={block.id}>
          <Fade duration={750}>
            <TextContainer>
              {documentToReactComponents(block.content.json)}
            </TextContainer>
          </Fade>
        </ContainerStyled>
      );
    }

    if (blockType === "ContentfulBlockFullWidthImage") {
      pageBlocks.push(
        <FullWidthImage
          image={block.image && block.image.fluid}
          key={block.id}
          videoUrl={block.videoUrl && block.videoUrl}
        />
      );
    }

    if (blockType === "ContentfulCarousel") {
      pageBlocks.push(<Carousel block={block} key={block.id} />);
    }
  });

  return (
    <LayoutStyled colorScheme="dark">
      <SEO
        title={project.title}
        description={documentToPlainTextString(project.description.json)}
        fullTitle={true}
        path={path}
      />
      <PageBackground>
        <ProjectHero project={project} />
        {pageBlocks}
      </PageBackground>
      {nextArticleData && (
        <NextProject
          next={nextArticleData.slug}
          clientName={nextArticleData.clientName}
          clientImage={nextArticleData.clientImage}
          articleLists={articleLists}
        />
      )}
    </LayoutStyled>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      slug
      description {
        json
      }
      clientName
      specialism {
        id
        name
        slug
      }
      industry {
        id
        name
      }
      blocks {
        __typename
        ... on Node {
          ... on ContentfulBlockImage {
            id
            image {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlockTwoImages {
            id
            withBackground
            firstImage {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            secondImage {
              fluid(maxWidth: 1000, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlockFullWidthImage {
            id
            videoUrl
            image {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlockTextImage {
            id
            imagePosition
            videoUrl
            content {
              json
            }
            image {
              fluid(maxHeight: 800, quality: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlockText {
            id
            content {
              json
            }
          }
          ... on ContentfulCarousel {
            id
            slide {
              ... on ContentfulBlockImage {
                id
                image {
                  fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulBlockTextImage {
                id
                imagePosition
                videoUrl
                content {
                  json
                }
                image {
                  fluid(maxHeight: 800, quality: 60) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allContentfulProject {
      nodes {
        slug
        clientName
        thumbnail {
          file {
            url
          }
        }
      }
    }
  }
`;
