import React, {Component} from "react";
import TextImageBlock from "./TextImageBlock";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import Slider from "react-slick/lib";
import FullWidthImage from "./FullWidthImage";
import styled from "styled-components";
import theme from "../../styles/theme";
import arrowLeft from "../../resources/images/ui/arrow-left.svg";
import arrowRight from "../../resources/images/ui/arrow-right.svg";

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: auto;
`;

const StyledSlider = styled(Slider)`
  position: relative;
  z-index: 1;
`;

const Dots = styled.ul`
  list-style: none;
  bottom: 20px;
  z-index: 1;
  
  li {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid ${theme.colors.grey};
    border-radius: 50px;
    margin: 0 8px;
  }
  
  .slick-active{
    background:  ${theme.colors.grey};
     :before {
      content: '';
      height: 12px;
      width: 12px;
      cursor: pointer;
      border: 1px solid ${theme.colors.grey};
      border-radius: 50px;
      position: absolute;
      top: -2px;
      left: -2px;
      transition: 0.2s;
      z-index: 100;
    }
  }
  
  @media(min-width: ${theme.breakpoints.medium}) {
    bottom: 40px;
    li {
      margin: 0 10px;
      height: 14px;
      width: 14px;
    }
    
    .slick-active {
      :before {
        height: 16px;
        width: 16px;
      }
    }
  }
`;

const Dot = styled.div`
  height: 9px;
  width: 9px;
  
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 16px;
    width: 16px;
  }
`;

const NextArrow = styled.span`
  display: none;
  position: absolute;
  z-index: 505;
  background: url(${arrowRight}) no-repeat;
  height: 20px;
  width: 20px;
  cursor: pointer;
  top: calc(50% - 10px);
  right: 20px;
  opacity: 1;
  
  &:hover {
    opacity: 1;
  }
  
  @media(min-width: ${theme.breakpoints.medium}) {
    display: block;
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
    right: 40px;
  }
`;

const PrevArrow = styled.span`
  display: none;
  background: url(${arrowLeft}) no-repeat;
  position: absolute;
  height: 20px;
  cursor: pointer;
  z-index: 505;
  width: 20px;
  top: calc(50% - 10px);
  left: 20px;
  
  @media(min-width: ${theme.breakpoints.medium}) {
    display: block;
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
    left: 40px;
  }
`;

class Carousel extends Component {
  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    const {block} = this.props;

    let slides = block.slide;
    let slideBlocks = [];

    const settings = {
      customPaging: function () {
        return (<Dot/>)
      },
      appendDots: dots => (
        <Dots>
          {dots}
        </Dots>
      ),
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      pauseOnHover: true,
      swipe: true,
      swipeToSlide: true,
      draggable: true
    };

    slides.forEach((slide) => {
      let blockType = slide.__typename;

      if (blockType === 'ContentfulBlockImage') {
        slideBlocks.push(<FullWidthImage image={slide.image && [slide.image.fluid]} key={slide.id} carousel/>);
      }

      if (blockType === 'ContentfulBlockTextImage') {
        slideBlocks.push(<TextImageBlock key={slide.id} videoUrl={slide.videoUrl}
                                         image={slide.image && [slide.image.fluid]}
                                         content={documentToReactComponents(slide.content.json)}
                                         type={slide.imagePosition && slide.imagePosition === true ? 'imgFirst' : ''}/>);
      }
    });
    return (
      <SliderContainer>
        <StyledSlider ref={c => (this.slider = c)} {...settings}>
          {slideBlocks}
        </StyledSlider>
        <NextArrow onClick={this.next} className="cursor-dot-black"/>
        <PrevArrow onClick={this.previous} className="cursor-dot-black"/>
      </SliderContainer>
    );
  }
}

export default Carousel
