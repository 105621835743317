import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {Container} from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ImagesBlockContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: auto;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding: 6px 0;
    flex-direction: row;
  }
`;
const Left = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    margin: 0 6px 0 0;
  }
`;

const Right = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 50%;
    margin: 0 0 0 6px;
  }
`;

const ProjectImage = styled(Img)`
  width: 100%;
  height: 100%;
  max-height: 350px;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-height: unset;
  }
`;

const ImageBlock = styled.div`
  height: 100%;
  width: 100%;
`;

const LargeImagesBlock = (props) => {
  return (
    <ImagesBlockContainer>
      <Left>
        <ImageBlock>
          <Fade duration={750} delay={100}>
            <ProjectImage fluid={props.projectImage[0]}/>
          </Fade>
        </ImageBlock>
      </Left>
      <Right>
        <ImageBlock>
          <Fade duration={750} delay={100}>
            <ProjectImage fluid={props.projectImage[1]}/>
          </Fade>
        </ImageBlock>
      </Right>
    </ImagesBlockContainer>
  )
};

export default LargeImagesBlock;
