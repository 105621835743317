import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ProjectImageBlockBackground = styled.div`
  width: 100%;
  background: ${theme.colors.greyProjectImg};
  height: 250px;
  overflow: hidden;
  padding-top: ${props => props.multiple === 'true' && '25px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; 
  margin: 6px 0;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 400px;
  }
  @media(min-width: ${theme.breakpoints.large}){
    height: 550px;
  }
  @media(min-width: ${theme.breakpoints.xl}){
    height: 870px;
  }
`;

const ProjectImage = styled(Img)`
  height: auto;
  max-height: 800px;
  max-width: 1600px;
  object-fit: contain;
  width: ${props => props.multiple === 'true' ? '35%' : '65%'}; 
  margin-right: ${props => props.multiple === 'true' && '25px'};
  margin-left: ${props => props.multiple === 'true' && '25px'};
`;

const ProjectImageBlock = (props) => {
    const { projectImage } = props;
    return (
        <ProjectImageBlockBackground multiple={projectImage && projectImage.length > 1}>
            <StyledContainer>
                <ProjectImage fluid={projectImage && projectImage[0]} multiple={projectImage && projectImage.length > 1} />
                {projectImage && projectImage.length > 1 && <ProjectImage fluid={projectImage && projectImage[1]} multiple={projectImage && projectImage.length > 1} />}
            </StyledContainer>
        </ProjectImageBlockBackground>
    )
}

export default ProjectImageBlock;
