import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import {Row, Col, Container} from 'styled-bootstrap-grid';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import theme from '../../styles/theme';

const ProjectHeroContainer = styled(Container)`
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 120px;
    box-sizing: border-box;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 236px;
        box-sizing: border-box;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 164px;
        padding-right: 164px;
    }
`;

const RowStyled = styled(Row)`
  padding-bottom: 70px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-bottom: 123px;
  }
`;

const ColStyled = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const BigTitle = styled.div`
    ${fonts.CircularMedium};
    letter-spacing: 0px;
    color: ${theme.colors.black};
    font-size: 35px;
    line-height: 42px;
    padding-bottom: 30px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 62px;
        line-height: 74.4px;
        max-width: 480px;
        padding-right: 35px;
    }
`;

const DescriptionBottom = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.black};
    font-size: 16px;
    line-height: 20px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
        margin-top: 5px;
    }
`;

const Details = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 45px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 35px;
        padding-bottom: 0;
    }
`;

const Title = styled.div`
    ${fonts.MaisonNeueDemi};
    color: ${theme.colors.black};
    font-size: 16px;
    line-height: 26.2px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const Description = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.black};
    font-size: 16px;
    line-height: 26.2px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const Specialism = styled.div`
    padding-right: 70px;
    @media(min-width:  ${theme.breakpoints.medium}) {
        padding-right: 140px;
    }
`;

const ProjectHero = ({project}) => {

  return (
    <ProjectHeroContainer>
      <RowStyled>
        <ColStyled md={6}><BigTitle>{project.title}</BigTitle></ColStyled>
        <ColStyled md={6}>
          <DescriptionBottom>{documentToReactComponents(project.description.json)}</DescriptionBottom>
          <Details>
            <Specialism>
              <Description>
                {project.specialism &&
                <>
                  <Title>Specialism</Title>
                  <ul>
                    {project.specialism.map((item, index) => {
                      return (
                        <li key={index}>{item.name}</li>
                      )
                    })}
                  </ul>
                </>}
              </Description>
            </Specialism>
            <div>
              <Title>Industry</Title>
              <Description>{project.industry.name}</Description>
            </div>
          </Details>
        </ColStyled>
      </RowStyled>
    </ProjectHeroContainer>
  )
};

export default ProjectHero;
